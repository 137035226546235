.gou-pagination {
    @include xy-grid();
    margin: 0.625rem;
    padding-bottom: 0.5em;
    border-bottom: rem-calc(1) $medium-gray solid;
    text-align: center;

    @include breakpoint(medium) {
        margin: 1rem;
    }

    ul {
        @include xy-cell(100%, true, 0);
        margin-bottom: 0;

        @include breakpoint(medium) {
            @include xy-cell(auto, true, 0);
            text-align: right;
        }
    }

    li {
        line-height: 1;

        &:last-child {
            @include breakpoint(medium) {
                margin-right: 0;
            }
        }
    }

    a {
        background-color: $light-gray;
        transition: background-color $global-easing 0.25s;
        text-decoration: none;
    }

    &__count {
        @include xy-cell(100%, true, 0);
        margin-top: 0.5em;

        @include breakpoint(medium) {
            @include xy-cell(shrink, true, 0);
            text-align: left;
        }
    }
}
