// A lazyloading style
.blur-up {
    opacity: 1;
    filter: blur(5px);
    transition: filter 400ms;

    &.lazyloaded {
        filter: blur(0);
    }
}

.required-text {
    color: get-color(alert);
}

// Layout helper
.pos-rel {
    position: relative;
}

// Some default animations and classes
.drop-and-fade-in {
    @include mui-animation(slide(in, down, 2em), fade(in));
}

.lift-and-fade-out {
    @include mui-animation(slide(out, up, 2em), fade(out));
}
// Create Utilite classes for colors of various tints
@include color-classes($gough-black, 'gough-black');
@include color-classes($light-gray, 'light-gray');
// Create Utilite classes for each breakpoint, for each value, for each property defined in the prefix array
$space-values: ( 0, 0.25rem, 0.5rem, 0.75rem, 1rem, 2rem, 3rem, 4rem, 5rem, 6rem, ) !default;
$space-prefixes: ( p: padding, pt: padding-top, pb: padding-bottom, m: margin, mt: margin-top, mb: margin-bottom, mr: margin-right, ml: margin-left, ) !default;

@include make-space($space-values, $space-prefixes, $breakpoints);
