.gou-diagram {
    background: linear-gradient(to bottom, rgba($black, 0) 30%, rgba($black, 1) 60%);

    @include breakpoint(medium) {
        padding-bottom: 4rem;
    }

    @include breakpoint(large) {
        background: none;
    }

    &__container {
        @include xy-grid-container;
    }

    &__wrapper {
        position: relative;
        margin-right: 1.625rem;
        margin-left: 1.625rem;

        @include breakpoint(medium) {
            margin-right: 0.625rem;
            margin-left: 0.625rem;
        }
        @include breakpoint(large) {
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }

    &__figure {
        display: block;
        width: 100%;
        height: 2em;
        padding-top: 2rem;
        border-top: rem-calc(1) currentColor solid;

        @include breakpoint(small) {
            height: rem-between(2rem, rem-calc(486) + 4rem, map-get($breakpoints, small), map-get($breakpoints, large));
        }

        @include breakpoint(large) {
            padding-top: 4rem;
        }

        @include breakpoint(xlarge) {
            height: 42.5rem;
        };
    }

    &__captions {
        @include xy-grid();
        margin: 2rem -1rem;
        list-style: none;

        @include breakpoint(large) {
            margin: 0;
        }
    }

    &__caption {
        @include xy-cell(100%, false, 0);
        margin-bottom: 1.5em;

        @include breakpoint(medium) {
            @include xy-cell(1 of 2, true, $gou-header-gutter , margin, right left, null, false);
        }

        @include breakpoint(large) {
            @include xy-cell(24%, false, 0)
        }

        &:nth-child(1) {
            @include breakpoint(large) {
                position: absolute;
                top: 20%;
                left: -(map-get($grid-column-gutter, 'medium') / 2);
            }
        }

        &:nth-child(2) {
            @include breakpoint(large) {
                position: absolute;
                top: 65.5%;
                left: -(map-get($grid-column-gutter, 'medium') / 2);
            }
        }

        &:nth-child(3) {
            @include breakpoint(large) {
                position: absolute;
                top: 27%;
                right: -(map-get($grid-column-gutter, 'medium') / 2);
                text-align: right;
            }
        }

        &:nth-child(4) {
            @include breakpoint(large) {
                position: absolute;
                top: 71.5%;
                right: -(map-get($grid-column-gutter, 'medium') / 2);
                text-align: right;
            }
        }
    }
}
