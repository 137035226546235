.gou-finder {
    @include xy-grid-container;
    padding-top: 1em;
    padding-bottom: 4em;

    &__wrapper {
        @include xy-grid();
    }

    &__address-container {
        @include xy-cell(100%, true, map-get($grid-column-gutter, 'medium'), margin, right bottom left);

        @include breakpoint(medium) {
            @include xy-cell(50%, true, map-get($grid-column-gutter, 'medium'), margin, right left);
            margin-bottom: 0;
        }
    }

    &__search-radius-container {
        @include xy-cell(100%, true, map-get($grid-column-gutter, 'medium'), margin, right bottom left);

        @include breakpoint(medium) {
            @include xy-cell(25%, true, map-get($grid-column-gutter, 'medium'), margin, right left);
            margin-bottom: 0;
        }
    }

    &__actions {
        @include xy-cell(100%, true, map-get($grid-column-gutter, 'medium'), margin, right bottom left);

        @include breakpoint(medium) {
            @include xy-cell(25%, true, map-get($grid-column-gutter, 'medium'), margin, right left);
            margin-bottom: 0;
        }
    }

    &__search-button {
        @include button($button-padding, $gou-card-link-bg-color, false);
        @include gough-button();
        @include button-expand(true);
        margin-bottom: 0;
    }

    &__search-results {
        @include xy-cell(100%, true, map-get($grid-column-gutter, 'medium'));
    }

    &__map {
        @include xy-cell(100%, true, map-get($grid-column-gutter, 'medium'), margin, top left right bottom);
    }

    &__map-inner {
        @include responsive-embed(1 by 1);
        border-radius: rem-calc(3);
        background-color: $light-gray;

        @include breakpoint(medium) {
            @include responsive-embed(16 by 9);
        }

    }

    &__ui {
        @include breakpoint(medium) {
            @include xy-grid();
            @include flex-align(left, middle);
        }

        label {
            @include breakpoint(medium) {
                @include xy-cell(shrink, true, map-get($grid-column-gutter, 'small'));
            }
        }

        input,
        select {
            margin-bottom: 0;
            @include breakpoint(medium) {
                @include xy-cell(auto, true, map-get($grid-column-gutter, 'small'));
            }
        }
    }
}
