$gou-search-bg-color: lighten($dark-gray, 10%);

.gou-search {
    @include xy-grid();
    padding-right: 3.5em;
    background-color: $gou-search-bg-color;
    font-family: $gough-heading-font;
    font-weight: 700;
    @include breakpoint($gou-extra-breakpoint) {
        position: relative;
        padding-right: 0;
    }

    &__label {
        @include element-invisible;
    }

    &__input {
        @include xy-cell(auto, false, 0);
        margin-bottom: 0;
        padding-right: 1.5em;
        padding-left: 1.5em;
        border-color: $gou-search-bg-color;
        border-radius: 0;
        background-color: $gou-search-bg-color;
        font-size: inherit;
        min-width: 10em;
        color: $white;

        &::placeholder {
            color: $white;
        }

        &:focus {
            border-color: $gou-search-bg-color;
            background-color: $gou-search-bg-color;
            color: $white;
        }
    }

    &__button {
        @include xy-cell(shrink, false, 0);
        @include button();
        margin-bottom: 0;
        font-size: inherit;
    }
}
