.gou-main-content {
    max-width: 50em;
    margin: 0 auto;
    padding-top: 2em;
    padding-bottom: 2em;

    .gou-search-result-page &,
    .gou-landing--branches & {
        padding-bottom: 0.5em;
    }

    .gou-inner--branch & {
        padding-bottom: 1em;
    }

    &__link {
        margin-top: 1em;
    }

    &__heading {
        margin-top: 0;
        margin-bottom: 0.425em;
    }

    &__image {
        text-align: center;
    }

    &__content {

        h2,
        h3 {
            margin-top: 2rem;
        }

        a {
            color: inherit;
        }

        img {
            // sass-lint:disable force-pseudo-nesting
            width: 100%;

            + img {
                margin-top: 1em;
                @include breakpoint(medium) {
                    margin-top: 0;
                }
            }

            &:last-child:nth-child(2),
            &:first-child:nth-last-child(2) {
                @include breakpoint(medium) {
                    width: calc(50% - 0.5em);
                    margin-right: 0.5em;
                }
            }

            &:last-child:nth-child(2) {
                @include breakpoint(medium) {
                    margin-right: 0;
                }
            }

            &:last-child:nth-child(3),
            &:nth-child(2):nth-last-child(2),
            &:first-child:nth-last-child(3) {
                @include breakpoint(medium) {
                    width: calc((100% / 3) - (1.5em / 3));
                    margin-right: 0.5em;
                }
            }

            &:last-child:nth-child(3) {
                @include breakpoint(medium) {
                    margin-right: 0;
                }
            }
        }
    }


    &--full-width {
        max-width: none;

        .gou-main-content__container {
            @include xy-grid-container;
        }

        .gou-main-content__wrapper {
            @include xy-grid();

            @include breakpoint(large) {
                @include flex-align(justify, top);
            }
        }

        .gou-main-content__content,
        .gou-main-content__link,
        .gou-main-content__image {
            @include xy-cell(100%, true, $grid-column-gutter, margin, right left, null, false);
        }

        .gou-main-content__content {
            @include breakpoint(large) {
                @include xy-cell(64%, true, map-get($grid-column-gutter, 'medium'), margin, right left, null, false);
            }
        }

        .gou-main-content__link,
        .gou-main-content__image {
            @include breakpoint(large) {
                @include xy-cell(33%, true, map-get($grid-column-gutter, 'medium'), margin, right left, null, false);
            }
        }

        .gou-main-content__link {
            @include breakpoint(large) {
                margin-top: 3.75rem;
            }
        }
    }

    &--bg-image {
        padding-bottom: 2em;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;

        @include breakpoint(large) {
            padding-top: 3em;
            padding-bottom: 3em;
        }
    }
 }
