.gou-figure-list {
    @include component-reset;
    margin: 2em 0;
    list-style: none;

    @include breakpoint(medium) {
        margin-bottom: 4em;
    }

    &__item {
        @include xy-grid-container;
        padding-left: 1em;
        padding-right: 1em;

        @include breakpoint(large) {
            padding-left: 1em;
            padding-right: 1em;
        }
    }
}
