body{
  background-color: #404851;
  background-image: url(../img/bg-oilswirl.jpg);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;

  p, h1, h2, h3, h4, h5, h6, .menu{
    text-transform: none;
  }

  p{
    font-family: "Neue Montreal", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.45px;
  }

  .off-canvas-content,
  .off-canvas-wrapper {
    //background-color: rgba(60, 72, 83, 0.7);
  }
  .off-canvas-content{
    #main-content{
      //min-height: calc(100vh - 223.2px - 178.97px);
      background: unset;
    }
  }
  .off-canvas{
    background-color: #3c4853;
    .drilldown a{
      background-color: #3c4853;
    }
    .gou-main-nav__close-button {
      margin-left: 77%;
    }
  }
  .gou-main-nav--vertical .is-drilldown-submenu{
    background-color: #3c4853;
    box-shadow: 3px 3px 5px 6px rgba(0,0,0,0.15);
    border: 1px solid #f5bf0b;
  }
  .search-results-container, .main-page-contents{
    padding: 128px 0 64px;
  }

   .off-canvas.is-closed {
       opacity: 0;
   }

  .gou-main-nav__close-button .svg-icon{
    color: #fff;
  }
  .gou-main-nav--vertical .menu .active > a {
    background: #f5bf0b !important;
  }

  .gou-header {
    .gou-header__container{
      padding-bottom: 20px;
    }
    .gou-header__nav-toggle button{
      margin-top: 0;
      margin-left: 10px;
    }
    .gou-header__nav-toggle button::after {
      background: #fff;
      box-shadow: 0 0.7875em 0 #fff, 0 1.575em 0 #fff;
    }

    .gou-search{
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      padding: 90px 0 10px;
      width: 100vw;
      z-index: 9999;
      display: none;
      transition: all 0.3s ease;

      &__container{
        @include xy-grid-container;
        padding: 0;
        margin: 0 auto;
        display: flex;
        position: relative;
      }

      &.active{
        display: block;
      }

      .gou-search__input{
        border-color: #fff;
        background-color: #fff;
        color: #3c4853;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: 0.55px;
        transition: all 0.3s ease;

        &::placeholder {
          color: #3c4853;
          font-size: 22px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.5;
          letter-spacing: 0.55px;
        }
        &:focus{
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
      .gou-search__button{
        border-radius: 25px;
        background: none;
        border: 1px solid #edc043;
        color: #edc043;
        height: 50px;
        width: 50px;
        margin-bottom: 10px;

        .svg-icon {
          vertical-align: text-bottom;
        }
      }
      button.gou-search__close-button{
        background: none;
        color: rgba(60, 72, 83, 0.42);
        height: 16px;
        width: 16px;
        position: absolute;
        top: -70px;
        right: 32px;
        transition: all 0.3s ease;

        &:hover{
          color: rgba(60, 72, 83, 0.82);
        }
      }
    }

    .gou-header__nav-toggle{
      position: absolute;
      right: 50px;
      top: 50px;
    }

    .gou-header__buttons{
      display: flex;
      align-self: end;

      .gou-header__button{
        transition: all 0.3s ease;

        &.search-button{

          svg{
            width: 1em;
          }
        }

        &:hover{
          color: #f5bf0b;
          border-color: #f5bf0b;
        }

        &.rounded-button{
        }
        &.orange-button{
          &:hover{
            color: #fff;
            border-color: #fff;
          }
        }
      }
    }

    .gou-header__bottom{

      .gou-header__bottom-container{

        .gou-header__logo{
          display: none;
          .gou-header__logo-link{
            width: 200px;
            img{
              max-height: 58px;
              vertical-align: top;
            }
          }
        }
        a.gou-header__button.rounded-button.search-button {
          display: none;
          max-height: 50px;
        }

        ul.menu{
          font-family: Neue Montreal, sans-serif;
          font-weight: 500;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 20px 0;

          li.gou-main-nav__item{
            margin-right: 2%;
            flex-grow: 1;
            border-top: 1px solid #fff;
            font-size: 1.2em;

            &:last-child{
              margin-right: 0;

            }
            &:hover{
              //color: #f5bf0b !important;
            }

            &.active{
              > a.gou-main-nav__link{
                background: unset !important;
                color: #f5bf0b !important;
              }

            }

            a.gou-main-nav__link{
              padding: 18px 0 0 !important;
              background: unset;
              color: #fff;

              .gou-main-nav__link-text{
                text-transform: none;
                letter-spacing: 0.6px;
                font-weight: 400;
                padding-bottom: 10px;
                &:hover{
                }
              }
              &:hover{
                  color: #f5bf0b !important;
              }
            }

            .gou-main-nav__icon {
              top: 18px;
              transform: rotate(90deg);

              svg {
                display: block;
              }
            }

            &.is-dropdown-submenu-parent{
              &.is-active{
                a{
                  //color: #383f45 !important;
                }
                .submenu{
                  margin-top: 28px;
                  padding: 0;
                  min-width: 320px;

                  &:before{
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -17px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0px 17px 17px 0px;
                    border-color: transparent transparent #ffffff transparent;
                    z-index: 9999;
                  }

                  &.js-dropdown-active{
                    li{
                      border-bottom: 1px solid #000;

                      &:last-child{
                        border-bottom: none;
                      }
                      &:hover{
                        background-color: rgba(60, 72, 83, 0.1);
                      }

                      a{
                        color: #000;
                        padding: 14px 20px;
                        position: relative;

                        span{
                          text-transform: none;
                          font-size: 1.1em;
                        }

                        .gou-main-nav__icon {
                          transform: none;
                          top: 11px;

                          svg {
                            display: block;
                          }
                        }
                      }

                      &.active{
                        a{
                          background-color: rgba(245, 191, 11, 0.6) !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.fixed-header{
        display: block;
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #3c4853;
        -moz-box-shadow:    0px 10px 20px 20px rgba(0,0,0,0.15);
        -webkit-box-shadow: 0px 10px 20px 20px rgba(0,0,0,0.15);
        box-shadow:         0px 10px 20px 20px rgba(0,0,0,0.15);
        padding-bottom: 15px;

        .gou-header__logo{
          display: block;
        }
        nav#js-gou-main-nav{
          ul.menu.dropdown{
            padding: 40px 0 0;
          }

        }
        a.gou-header__button.rounded-button.search-button{
          display: block;
          align-self: flex-end;
          margin-bottom: 6px;
        }
      }
    }
    .gou-background-transparent{
      background: unset;
      color: #fff;

    }
  }

  main.gou-landing__main{
    background-color: unset;

    .gou-background-transparent{
      background: unset;
      color: #fff;
    }
  }
  .gou-section-grid.resources-page{
    .gou-section-grid__item{
      .gou-card{
        .gou-card__content{
          position: relative;
          display: block;
          padding: 10px 0;
          width: 100%;

          .gou-card__image{
            margin: 0;
          }
          .gou-card__heading{
            padding: 10px;
            svg{
              display: none;
            }
          }
          .gou-card__subheading{
            margin: -10px 0 0;
            padding: 0 10px 10px;
          }
          .gou-card__link{
            margin: 20px auto;
            position: relative;
            display: block;
            max-width: 150px;
            svg{
              margin: 0;
            }
          }
        }
      }
    }

  }
  .gou-homeheadlines{
    .gou-section-grid{
      .gou-homeheadlines__wrapper{
        margin: 1rem;
        padding-top: 128px;
        padding-bottom: 64px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        .headline-text__container{
          width: 80%;
          color: #fff;
            h1.main-heading{
              text-transform: none;
              font-size: 100px;
              font-weight: 500;
              letter-spacing: 2.5px;
              line-height: 1;
              max-width: 982px;
            }
          h3.sub-heading{
            text-transform: none;
            margin-top: 28px;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.46;
            letter-spacing: 0.6px;
            max-width: 782px;
          }

        }
        .headline-chat__container{
          width: 20%;
          display: flex;
          align-items: flex-end;
          align-content: flex-end;
          justify-content: flex-end;
        }
      }
    }
  }

  .gou-header__button.chat-button {
    align-self: flex-end;
    justify-self: baseline;
    min-width: 111px;
    padding: 0.8em 1em;
    background-color: #f5bf0b;
    border-color: #f5bf0b;
    color: #3c4853;
    font-weight: 700;

    svg{
      float: left;
      transform: scale(1.25);
      margin-left: 5px;

      path {
        //stroke: #3c4853;
        //stroke-width: 20;
        fill: #3c4853;;
      }
    }

    &:hover{
      background-color: #fff;
      border-color: #fff;

    }
  }
  .gou-section-grid{
    .gou-section-grid__heading{
      font-size: 55px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.82;
      letter-spacing: 1.38px;
      text-transform: none;
    }
    .gou-section-grid__content{
      p{
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: 0.45px;
      }
    }

    .gou-card{
      .gou-card__content{
        .gou-card__image {
          display: block;
          margin-bottom: -95px;
        }
        h3.gou-card__heading {
          text-transform: none;
          position: relative;
          padding: 35px 30px;
          height: auto;
          margin-bottom: 0;

          svg {
            display: block;
            position: absolute;
            right: 30px;
            top: 35px;
          }
        }
      }
    }
  }
  .section-standard-grid-block .swiper2{
    .swiper-wrapper{
      flex-flow: unset;
    }
    .swiper-slide{
      box-shadow: 0px 6px 10px 2px rgba(0,0,0,0.1);
    }
  }

  .section-standard-grid-block{
    .gou-section-grid .gou-card .gou-card__content h3.gou-card__heading {
      background: white;
    }
  }

  .section-grid-slider{
    .swiper{
      width: calc(100% + 180px);
    }
    .gou-section-grid__container{
      position: relative;

      .slide-buttons{
        line-height: 0;
        position: absolute;
        right: 0;
        top: 20px;

        .swiper-button-prev {
          line-height: 0;
          display: inline-block;
          transform: rotate(180deg);

          svg{
            width: 32px;
            height: 32px;
          }
        }

        .swiper-button-next {
          display: inline-block;

          svg{
            width: 32px;
            height: 32px;
          }
        }

      }
    }

    .gou-section-grid__wrapper{
      flex-flow: unset;

      .section-grid-slider-item{
        width: 475px;

        .gou-card{

          .gou-card__content{

            .gou-card__image{
              margin-bottom: 10px;
            }
            h3.gou-card__heading{
              padding: 10px 0 0;

              svg{
                display: inline-block;
                margin-left: 15px;
                position: absolute;
                top: unset;
                right: unset;
              }

            }
          }
        }
      }
    }

  }

  .gou-section-text-cta-block__container {
    margin: 1rem;
    padding: 0 0 2rem;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;

     .gou-section-text-cta__heading{
       font-size: 55px;
       font-weight: 500;
       text-transform: none;
       font-stretch: normal;
       font-style: normal;
       line-height: 1.82;
       letter-spacing: 1.38px;
       width: 100%;
     }
    .gou-section-text-cta__top {
      width: 65%;
    }
    .gou-section-text-cta__button {
      width: 35%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  .gou-page-header__container{
    background: unset;
    .gou-page-header__inner{
      margin-right: 1rem;
      margin-left: 1rem;
      background: unset;
      margin: 1rem;
      padding-top: 88px;
      padding-bottom: 48px;
      display: flex;
      flex-flow: row wrap;
      color: #fff;

      .gou-page-header__text-left{
        width: 80%;

        h1{
          font-size: 100px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 2.5px;
          text-transform: none;
          margin-bottom: 24px;
        }

        .gou-breadcrumbs{
          background: unset;
          color: #fff;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: 0.45px;
          text-transform: none;

          .breadcrumbs{
            padding: 0;

            li{
              color: #fff;
              text-transform: none;

            }
          }
        }

      }
      .gou-page-header__cta-right{
        width: 20%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

      }

    }

  }

  #main-content > .inner-page-contents, #main-content > .gou-main-content, #main-content > .contact-container, #main-content.gou-inner.gou-inner--video__main .video-page-template, .section-landing-page, .search-results-container, .main-page-contents{
    background-color: #fff;
  }
  .search-results-container, .main-page-contents{
    min-height: 475px;
  }
  #main-content > .gou-main-content, #main-content > .inner-page-contents, #main-content.gou-inner.gou-inner--video__main .video-page-template{
    padding-top: 128px;
  }
  #main-content > .inner-page-contents > .inner-page-contents > .grid-x > .cell > .gou-main-content {
    max-width: 100%;
    padding: 0;
    //.gou-main-content__container {
    //  margin: 1rem;
    //}
  }
  #main-content > .gou-main-content .gou-main-content__wrapper{
    //margin: 1rem;
    .gou-main-content__content {
      width: calc(75% - 2rem);
      margin: 0;
    }
  }
  #main-content > .inner-page-contents > .inner-page-contents > .grid-x > .cell {

    &> .gou-main-content > .gou-main-content__container > .gou-main-content__wrapper > .gou-main-content__content > .gou-main-content__text {

      &> h3{
        font-weight: 500;
        font-size: 25px;
        line-height: 1.6;
        letter-spacing: 0.63px;
        text-transform: none;
        max-width: 1322px;
        margin-bottom: 32px;
      }
      &> p{

        max-width: 1150px;
      }
    }

    &> .block_area_aftercontent{
      max-width: 100%;
      padding: 0;

      .gou-section-grid{
        padding: 0;

        .gou-section-grid-feature-text-box__container {
          position: relative;
          display: block;

          .gou-section-grid__heading {
            margin: 0;
            margin-top: 100px;
            margin-bottom: 16px;
            text-transform: none;
            font-size: 55px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.82;
            letter-spacing: 1.38px;
          }

          .gou-section-grid-feature-text-box__feature-container {

            .feature-text-box-gutter{
              width: 4%;
            }
            .feature-text-box{
              width: 48%;
              padding: 5% 5% 3%;
              margin-bottom: 50px;
              border: 2px solid #3c4853;
              box-sizing: border-box;

              &:nth-child(odd) {
                border-color: #eab60a;
              }
              &:nth-child(even) {
                border-color: #000;
              }

              .feature-title{
                font-size: 25px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 4;
                letter-spacing: 0.63px;
              }
            }
          }
        }
      }
    }
  }

  .section-testkit-block{

    .gou-section-grid-testkit__container{
      margin: 1rem;
      padding: 0 0 2rem;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      width: calc(100% + 2rem + 180px);
      overflow: visible;

      .gou-section-grid-testkit__inner-left{
        width: calc(50% - 180px);
        background-color: #f5bf0b;
        padding-top: 139px;
        padding-left: 88px;

        h2.gou-section-grid__heading{
          font-family: "Neue Montreal", sans-serif;
          text-transform: none;
          font-size: 80px;
          font-weight: 500;
          line-height: 1.13;
          letter-spacing: 2px;
          color: #fff;
          margin: 0;

          &:before {
            content: '';
            float: right;
            width: 30%;
            height: 1.13em;
          }
        }

        .gou-section-grid__button{
          margin-top: 50px;
        }

      }
      .gou-section-grid-testkit__inner-right{
        padding: 139px 180px 139px 0;
        width: calc(50% + 148px);
        background-color: #fcf5dd;
        color: #404852;

        .gou-section-grid-testkit__feature-container{
          padding-left: 160px;
          padding-right: 180px;
          margin-right: -180px;

          .feature{
            p{
              padding-top: 30px;
              padding-bottom: 30px;
              border-bottom: 2px solid #404852;
              font-weight: 500;
            }

            &:first-child P{
              padding-top: 0;
            }

            &:last-child p{
              border: 0;
              padding-bottom: 0;
            }


          }

        }
      }
    }
  }

  .section-text-block{
    margin-bottom: 30px;
    .gou-section-grid{
      .gou-section-grid-text-block__feature-container{
        margin: 1rem;
        .section-grid-text-block{
          .section-grid-text-block-title{
            font-family: "Neue Montreal", sans-serif;
            font-size: 25px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 0.63px;
            margin-bottom: 14px;
            max-width: 1322px;
          }
          p{
            margin-bottom: 30px;
            max-width: 1150px;
          }
        }

      }

    }
  }

  .section-accordion-text-block{
    padding-bottom: 30px;
    .gou-section-grid{
      .gou-section-grid-accordion-text-block__feature-container{
        .section-grid-accordion-text-block{
          position: relative;
          border-bottom: 1px solid #252525;
          max-width: 1346px;

          .section-grid-accordion-text-block-title{
            font-family: "Neue Montreal", sans-serif;
            font-size: 25px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: 0.63px;
            padding: 10px 10px 20px;

            &:hover{
              background-color: rgba(60, 72, 83, 0.1);
            }

            &:before{
              content: '+';
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          p{
            padding: 0 46px 0 10px;
            margin: 0;
            overflow: hidden;
            overflow-y: auto;
            height: 100%;
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s ease;
          }

          &.active{

            .section-grid-accordion-text-block-title{
              position: relative;

              &:before{
                content: '-';
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            p{
                padding: 0 46px 30px 10px;
                max-height: 2000px;
            }
          }
        }

      }

    }
  }

  .section-feature-icon-block{
    color: #3c4853;

    .gou-section-grid-feature-icon__container{
      margin: 1rem;

      .gou-section-grid-feature-icon__top{
        .gou-section-grid__heading{
          margin: 0;
          margin-top: 70px;
          padding-bottom: 50px;
          text-transform: none;
          font-size: 35px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.86;
          letter-spacing: 0.88px;
          color: #3c4853;
        }
      }

      .gou-section-grid-feature-icon__bottom{

        &.gou-dashcolor-grey{
          .gou-section-grid-feature-icon__feature-container .feature .feature-image:after{
            border-color: #3e4852;
          }
        }
        .gou-section-grid-feature-icon__list-container{
          display: none;
        }

        .gou-section-grid-feature-icon__feature-container{
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          .feature{
            max-width: 245px;
            margin-top: 21px;

            .feature-image{
              max-width: 245px;
              max-height: 245px;
              margin: 0 auto;
              position: relative;

              &:after{
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: -50%;
                width: 80px;
                border: 1px solid #e8bf33;
              }
            }
            &:last-child{
              .feature-image{
                &:after{
                  display: none;
                }
              }
            }

            p{
              margin-top: 36px;
              margin-bottom: 36px;
              text-decoration: none;
              font-size: 20px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              text-align: center;

            }
          }
        }

        .gou-section-grid__button{
            margin-top: 24px;
            margin-bottom: 80px;
        }
      }
    }
  }

  .section-video-block{
    padding: 80px 0;

    .gou-section-video-block{

      .gou-section-video-block__container {
        margin: 1rem;

        .video-container{
          max-width: 100%;
          position: relative;

          video{
            max-width: 100%;
            height: auto;
          }
          .play-button {
            position: absolute;
            width: 100%;
            height: 100%;
            top: -3px;
            left: 0;
            border: none;
            background-image: url('../img/play.png');
            background-repeat: no-repeat;
            background-position: center;
          }

          .play-button:focus {
            outline: none;
          }
        }

        .bottom-text-container{
          width: 100%;

          .text-container{
            margin-top: 48px;
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            h2.gou-section-video-block__heading {
              font-family: "Neue Montreal", sans-serif;
              text-transform: none;
              font-size: 35px;
              font-weight: 500;
              letter-spacing: 0.88px;
              width: 80%;
            }
            .gou-section-video-block__button{
              width: 20%;
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;

              a{
                font-family: "Neue Montreal", sans-serif;
                font-size: 22px;
                font-weight: 500;
                letter-spacing: 0.55px;
                padding: 1rem 0;
                text-align: right;
              }
            }

          }

        }

      }
    }
  }

  .gou-section__button{
    display: inline-block;
    min-width: 240px;
    padding: 1rem;
    font-family: "Neue Montreal", sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.45px;
    line-height: 18px;
    text-decoration: none;
    text-align: center;
    border: 1px solid #fff;
    transition: all 0.3s ease;

    &.rounded-button{
      border-radius: 25px;

      &.orange-button{
        border-color: #f5bf0b;
        color: #f5bf0b ;

        &:hover{
          background-color: #f5bf0b;
          color: #fff;
        }

        &.filled-button{
          background-color: #f5bf0b;
          color: #fff;

          &:hover{
            background-color: #fff;
            color: #f5bf0b;
          }

        }
      }
      &.white-border{
        border-color: #fff;
      }
    }

  }
  .home-page section.gou-content-block.section-testkit-block.gou-background-transparent {
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,0) 15%, rgba(255,255,255,0) 100%);
  }


  @media print, screen and (min-width: 40em){
    .grid-padding-x > .cell {
      padding: 0;
    }
    .grid-padding-x > .cell.branch-left {
      padding: 10px;
    }
  }
  .gou-footer {
    color: #3c4853;
    background: #fff;
    border-top: none;
    padding: 64px 0 16px;

    .gou-footer__wrapper{

      .gou-footer__top{

        nav.gou-footer__number {
          width: 20%;
          .gou-header__button.borderless-button{
            font-size: 22px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.55px;
            color: #383f45;
            min-width: 0;
            padding: 0;
            margin: 0;
            &:hover{
              color: #f5bf0b;
            }
            span{
              text-align: left;
            }

          }

        }
        .gou-footer__nav {
          margin-left: -1em;
          flex-grow: 1;

          ul.gou-menu.menu{
            display: block;

            li{
              a{
                font-size: 22px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.55px;
                text-transform: none;
              }
            }
          }
        }
        .gou-footer__cert{
          width: 175px;
        }
        .gou-footer__logos{
          width: 240px;

          a{
            margin-left: 0;
            margin-bottom: 25px;
          }
        }
      }

      .gou-footer__bottom{
        border-top: 1px solid #404852;
        padding: 35px 0 40px;

        .gou-footer__bottom-left{
          flex-grow: 1;
          p{
            font-size: 18px;
            line-height: 1.67;
            font-weight: 400;
            letter-spacing: 0.45px;
          }
        }

        .gou-footer__bottom-right{
          ul.gou-menu.menu{
            li{
              a{
                font-size: 18px;
                line-height: 1.67;
                font-weight: 400;
                letter-spacing: 0.45px;
                padding: 0 0 0.7rem 3rem;
                text-transform: none;

                &:after{
                  display: none;
                }
              }
            }
          }

        }

      }
    }
  }

  .gou-page-contact__bottom-content {
    padding: 10px 0 64px;
  }

  .gou-menu__link.gou-menu__link:hover, .current > .gou-menu__link.gou-menu__link, .active > .gou-menu__link.gou-menu__link {
    background-color: transparent;
    color: #f5bf0b;
  }
}
.gou-section-grid__bottom.swiper2.swiper-initialized.swiper-horizontal {
  overflow: hidden;
  width: 100%;
  .gou-section-grid__item{
    width: 350px;
  }
}


@media print, screen and (max-width: 1687px){
  body {
    background-position: 90% 0;
    background-size: 120%;
  }

  body .section-testkit-block .gou-section-grid-testkit__container{
    width: unset;
    .gou-section-grid-testkit__inner-left{
      width: 35%;
      padding: 70px 50px;

      h2.gou-section-grid__heading{
        font-size: 80px;
        &:before{
          display: none;
        }
      }
    }
    .gou-section-grid-testkit__inner-right{
      width: 65%;
      padding: 70px 50px;

      .gou-section-grid-testkit__feature-container{
        padding: 0;
        margin: 0;
      }
    }
  }
  body .gou-header .gou-header__bottom .gou-header__bottom-container .gou-header__logo .gou-header__logo-link {
    width: 125px;
  }

}

@media print, screen and (max-width: 1600px){
  body .section-feature-icon-block .gou-section-grid-feature-icon__container .gou-section-grid-feature-icon__bottom .gou-section-grid-feature-icon__feature-container .feature .feature-image:after{
    display: none;
  }
}
@media print, screen and (max-width: 1400px){
  body .section-testkit-block .gou-section-grid-testkit__container{
    width: unset;
    .gou-section-grid-testkit__inner-left{
      width: 100%;
      padding: 50px 50px;

      h2.gou-section-grid__heading{
        font-size: 80px;
        &:before{
          display: none;
        }
      }
    }
    .gou-section-grid-testkit__inner-right{
      width: 100%;
      padding: 50px 50px;

      .gou-section-grid-testkit__feature-container{
        padding: 0;
        margin: 0;
      }
    }
  }
  body .section-feature-icon-block .gou-section-grid-feature-icon__container .gou-section-grid-feature-icon__bottom .gou-section-grid-feature-icon__feature-container .feature{
    max-width: 200px;
  }

  body .gou-section-text-cta-block__container .gou-section-text-cta__heading {
    font-size: 45px;
  }
}

@media print, screen and (max-width: 1280px){

  body {
    background-position: 80% 0;
    background-size: 150%;
  }

  body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper .headline-text__container h1.main-heading{
    font-size: 80px;
  }

  body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper{
    padding-top: 64px;
  }

  .gou-header__button.orange-button, .gou-header__button{
    min-width: 180px;
    padding: 0.8em;
    margin-left: 10px;
  }

  body .gou-section-text-cta-block__container .gou-section-text-cta__heading {
    font-size: 35px;
  }
  body #main-content > .gou-main-content .gou-main-content__wrapper{
    .gou-main-content__content {
      width: calc(100% - 2rem);
    }
  }

}
@media print, screen and (min-width: 1024px){
  a[name]::before {
    content: '';
    display: block;
    padding-top: 200px;
    margin-top: -200px;
    visibility: hidden;
  }
  a[id]::before {
    content: '';
    display: block;
    padding-top: 200px;
    margin-top: -200px;
    visibility: hidden;
  }
}

@media print, screen and (max-width: 1024px){
  body .gou-header .gou-header__bottom.fixed-header{
    display: none;
  }
  body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper .headline-text__container{
    width: 100%;
  }
  body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper .headline-chat__container{
    width: 100%;
    justify-content: flex-start;
    .chat-button{
      margin-top: 15px;
      margin-left: 0;
    }
  }
  body .gou-header .gou-header__buttons{
    display: none;
  }
  .gou-header__buttons.mobile-menu{
    display: block;
    .gou-header__button{
      display: block;
      margin-top: 20px;
    }
  }
}

@media print, screen and (max-width: 992px){
  body {
    background-position: 70% 0;
    background-size: 180%;
  }
  body #main-content > .gou-main-content, body #main-content > .inner-page-contents, body #main-content.gou-inner.gou-inner--video__main .video-page-template {
    padding-top: 64px;
  }
  body p, body .gou-section-grid .gou-section-grid__content p {
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: 0.38px;
  }
  body .gou-section__button{
    font-size: 16px;
    letter-spacing: 0.4px;
  }
  .is-drilldown {
    overflow: visible;
  }
  body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper .headline-text__container h1.main-heading, body .section-testkit-block .gou-section-grid-testkit__container .gou-section-grid-testkit__inner-left h2.gou-section-grid__heading, body .gou-page-header__container .gou-page-header__inner .gou-page-header__text-left h1{
    font-size: 50px;
  }
  body .section-video-block .gou-section-video-block .gou-section-video-block__container .bottom-text-container .text-container {
    margin-top: 28px;
  }
  body .section-video-block .gou-section-video-block .gou-section-video-block__container .bottom-text-container .text-container h2.gou-section-video-block__heading{
    font-size: 24px;
  }
  body #main-content > .inner-page-contents > .inner-page-contents > .grid-x > .cell > .block_area_aftercontent .gou-section-grid .gou-section-grid-feature-text-box__container .gou-section-grid-feature-text-box__feature-container .feature-text-box .feature-title, body .section-accordion-text-block .gou-section-grid .gou-section-grid-accordion-text-block__feature-container .section-grid-accordion-text-block .section-grid-accordion-text-block-title, body .section-text-block .gou-section-grid .gou-section-grid-text-block__feature-container .section-grid-text-block .section-grid-text-block-title{
    font-size: 20px;
    letter-spacing: 0.5px;
  }
  body .gou-footer .gou-footer__wrapper .gou-footer__top nav.gou-footer__number .gou-header__button.borderless-button,body .gou-footer .gou-footer__wrapper .gou-footer__top .gou-footer__nav ul.gou-menu.menu li a{
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
    letter-spacing: 0.45px;
    padding: 5px 0;
  }
  body .section-video-block .gou-section-video-block .gou-section-video-block__container .bottom-text-container .text-container .gou-section-video-block__button{
    display: block;
  }
  body .home-page section.gou-content-block.section-testkit-block.gou-background-transparent {
    background: linear-gradient(0deg, white 0%, white 45%, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0) 100%);
  }
  body .section-testkit-block .gou-section-grid-testkit__container {

    .gou-section-grid-testkit__inner-left {
      padding: 65px 27px;

      .gou-section-grid__button {
        margin-top: 28px;
      }
    }
   .gou-section-grid-testkit__inner-right{
     padding: 50px 27px;
   }
  }

  body .section-video-block .gou-section-video-block .gou-section-video-block__container .bottom-text-container .text-container .gou-section-video-block__button a{
   font-size: 16px;
    width: 100%;
    min-width: 0;
    padding: 0;
    text-align: right;
  }
  body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper .headline-text__container h3.sub-heading, body #main-content > .inner-page-contents > .inner-page-contents > .grid-x > .cell > .gou-main-content > .gou-main-content__container > .gou-main-content__wrapper > .gou-main-content__content > .gou-main-content__text > h3, body .section-grid-slider .gou-section-grid__wrapper .section-grid-slider-item .gou-card .gou-card__content h3.gou-card__heading {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
  }
  body .section-grid-slider .gou-section-grid__wrapper .section-grid-slider-item .gou-card .gou-card__content h3.gou-card__heading svg{
    width: 20px;
    height: 20px;
  }

  //body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper .headline-text__container h1.main-heading{
  //  font-size: 80px;
  //}
  body .gou-section-text-cta-block__container{
    .gou-section-text-cta__top{
      width: 100%;
    }
    .gou-section-text-cta__button {
      width: 100%;
      justify-content: flex-start;
      margin-top: 15px;
    }
  }

  body .gou-section-text-cta-block__container .gou-section-text-cta__heading, body #main-content > .inner-page-contents > .inner-page-contents > .grid-x > .cell > .block_area_aftercontent .gou-section-grid .gou-section-grid-feature-text-box__container .gou-section-grid__heading, body .gou-section-grid .gou-section-grid__heading, body .section-feature-icon-block .gou-section-grid-feature-icon__container .gou-section-grid-feature-icon__top .gou-section-grid__heading {
    font-size: 30px;
    letter-spacing: 0.75px;
    margin-top: 0;

  }
  body .section-video-block .gou-section-video-block .gou-section-video-block__container .video-container .play-button{
    background-size: 25%;
  }
  body .section-grid-slider .gou-section-grid__container .slide-buttons{
    display: none;
  }
  body .gou-footer .gou-footer__wrapper {
    margin: 0 1rem;
  }
  body .gou-footer{
    .gou-footer__wrapper .gou-footer__top{
      nav.gou-footer__number, nav.gou-footer__nav, .gou-footer__cert, .gou-footer__logos{
        width: 50%;
        margin-bottom: 30px;
      }
    }
  }
  body .gou-footer .gou-footer__wrapper .gou-footer__bottom{

    .gou-footer__bottom-left, .gou-footer__bottom-right{
      width: 100%;

      .menu{
        justify-content: center;
      }
    }
  }
  body .section-feature-icon-block .gou-section-grid-feature-icon__container .gou-section-grid-feature-icon__bottom .gou-section-grid-feature-icon__feature-container .feature{
    width: 50%;
    max-width: unset;
  }
  body .gou-footer .gou-footer__wrapper .gou-footer__top nav.gou-footer__number .gou-header__button.borderless-button span{
    text-align: center;
  }
  body .off-canvas-wrapper{
    .gou-header {
      position: relative;
      z-index: 999;

      .gou-header__nav-toggle button  {
        position: relative;
        z-index: 999;
      }
    }
    .off-canvas-content.is-open-right.has-transition-push{
      transform: none;

      .gou-header__nav-toggle button{

      }
    }
    .js-off-canvas-overlay{

      &.is-overlay-fixed{
        background: none;
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }

    .off-canvas{
      &.position-right {
      }

      .gou-main-nav--vertical{
        max-width: 360px;
        margin: 0 auto;

        .gou-main-nav__close-button{
          display: none;
        }
        .gou-header__buttons.mobile-menu{
          max-width: 240px;
          .gou-header__button.borderless-button{
            padding: 40px 0 0;
            margin: 0;
            font-weight: 400;

          }
        }

        .is-drilldown {
          margin-top: 150px;

          .menu.vertical.drilldown{

            .active > a {
              background: none !important;
              color: #fff !important;
            }

            .is-drilldown-submenu{
              left: 0;
              display: block;
              z-index: 1;
              transform: none;
              position: relative;
              visibility: visible;
              background-color: none;
              box-shadow: none;
              border: none;
              max-height: 0;
              overflow-y: auto;
              height: 100%;
              max-height: 0;
              overflow: hidden;
              transition: all 0.5s ease;

              &.is-active{
                transform: none;
                max-height: 100%;
              }

              li.js-drilldown-back, li.is-submenu-parent-item{
                display: none;
              }
              &> li.gou-main-nav__child-item{
                font-weight: 400;
                position: relative;
                padding-left: 12px;

                &:before{
                  content: "-";
                  position: absolute;
                  display: block;
                  top: 4px;
                  left: 16px;
                }
              }

            }

            &>.gou-main-nav__item{
              font-size: 16px;
              font-weight: 400;

              &>.gou-main-nav__link{
                //display: none;
              }

              &.is-drilldown-submenu-parent{
                a.gou-main-nav__link{
                  font-size: 16px;
                  font-weight: 400;
                }

                &.a.is-drilldown-submenu {

                }
              }
              .gou-main-nav__icon {
                position: absolute;
                top: 0.5em;
                right: unset;
                padding-left: 0.5em;
              }
            }

          }
        }
      }
    }
    .off-canvas-content{

      .gou-header .gou-header__nav-toggle button{

        &:after {
          background: none;
          box-shadow: none;
        }

        #hamburger-menu {
          position: absolute;
          top: -6px;
          right: -5px;
          -webkit-flex-flow: column wrap;
          -ms-flex-flow: column wrap;
          flex-flow: column wrap;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          height: 24px;
          width: 38px;
          cursor: pointer;
          box-sizing: content-box;
          padding: 6px;
          z-index: 3;
          display: flex;
        }
        #hamburger-menu .bar-top,
        #hamburger-menu .bar-middle,
        #hamburger-menu .bar-bottom {
          height: 2px;
          background: #fff;
          border-radius: 0;
          margin: 0 -1px;
          -webkit-transform-origin: left;
          -ms-transform-origin: left;
          transform-origin: left;
          transition: all 0.5s;
        }
      }

      &.is-open-right.has-transition-push{
        .gou-header .gou-header__nav-toggle button{

          #hamburger-menu .bar-top {
            transform: rotate(45deg);
            margin: -3px -1px;
          }
          #hamburger-menu .bar-middle {
            transform: translateX(1rem);
            opacity: 0;
          }
          #hamburger-menu .bar-bottom {
            transform: rotate(-45deg);
            margin: -3px -1px;
          }
        }
      }
    }
  }
}

@media print, screen and (max-width: 787px){
  body {
    background-position: 70% 0;
    background-size: 220%;
  }
  body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper .headline-text__container h1.main-heading, body .section-testkit-block .gou-section-grid-testkit__container .gou-section-grid-testkit__inner-left h2.gou-section-grid__heading, body .gou-page-header__container .gou-page-header__inner .gou-page-header__text-left h1 {
    font-size: 42px;
    line-height: 1.07;
    letter-spacing: 1.05px;
  }
  body .section-video-block .gou-section-video-block .gou-section-video-block__container .bottom-text-container .text-container {
    margin-top: 24px;
  }
  body .section-video-block .gou-section-video-block .gou-section-video-block__container .bottom-text-container .text-container h2.gou-section-video-block__heading{
    font-size: 22px;
  }
  body .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper{
    padding-bottom: 0;
  }

}

@media print, screen and (max-width: 687px){
  body .gou-header__logo img{
    height: 89px;
    padding-left: 15px;
  }
  body .gou-footer{
    .gou-footer__wrapper {

      .gou-footer__top {
        flex-flow: column;
        margin-bottom: 0;

        nav.gou-footer__number, nav.gou-footer__nav, .gou-footer__cert, .gou-footer__logos {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          margin-bottom: 50px;
        }

        nav.gou-footer__number {
          margin-bottom: 32px;
        }

        .gou-footer__logos {
          order: 3;
        }

        .gou-footer__cert {
          order: 4;
        }
      }

      .gou-footer__bottom {
        padding: 51px 0 48px;

        .gou-footer__bottom-left{
          //p{
          //  margin-bottom: 0;
          //}

          span.footer-dash {
            display: none;
          }
          span.footer-publica {
            display: block;
          }
        }
        .gou-footer__bottom-right{

          ul.gou-menu.menu {
            flex-direction: column;

            li a {
              padding: 0;
            }
          }
        }
      }
    }
  }

  body .section-feature-icon-block{

    &.mobile-list-layout .gou-section-grid-feature-icon__container{
      margin: 0;

      .gou-section-grid-feature-icon__top{
        .gou-section-grid__heading{
          margin: 0 auto;
          padding-bottom: 10px;
          padding-top: 20px;
          font-size: 20px;
          line-height: 1.2;
          letter-spacing: 0.5px;
        }
      }

      .gou-section-grid-feature-icon__bottom{

        .gou-section-grid-feature-icon__feature-container .feature{
          width: 100%;
          display: flex;

          .feature-image{
            width: 40%;
            max-width: 120px;
          }
          p{
            width: 60%;
            align-self: center;
            text-align: left;
            padding-left: 15px;
          }
        }
        .gou-section-grid__button{
          margin-top: 50px;
          text-align: center;

        }
      }
    }

    &.mobile-block-layout{
      .gou-section-grid{
        margin: 0 1rem;

        .gou-section-grid-feature-icon__container{
          margin: 0 1rem;

          .gou-section-grid-feature-icon__top{
            .gou-section-grid__heading{
              margin: 0;
              padding: 20px 0;
              line-height: 1.5;

            }
          }

          .gou-section-grid-feature-icon__bottom{

            .gou-section-grid-feature-icon__feature-container .feature{
              width: 46%;
              margin-right: 2%;

              .feature-image{
                max-width: 180px;
                margin: 0;
              }
              p{
                display: none;

              }
            }
            .gou-section-grid__button{
              margin-top: 50px;
              text-align: center;

            }

            .gou-section-grid-feature-icon__list-container{
              display: block;
              margin: 32px 0 0;

              ul{
                list-style-type: none;
                margin-left: 0;

                li{
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 1.47;
                  letter-spacing: 0.38px;

                  &:before {
                    content: "-";
                    padding-right: 6px;
                  }
                }
              }
            }

            .gou-section-grid__button{
              margin-top: 50px;
              text-align: left;
              margin-bottom: 32px;
            }
          }

        }
      }
    }
  }
  body .gou-footer .gou-footer__wrapper .gou-footer__top nav.gou-footer__number .gou-header__button > span{
    display: block;
  }
  body .section-video-block{
    padding: 24px 0;

    .gou-section-grid{
      padding: 0;

      .gou-section-video-block__container{
        margin: 0;

        .bottom-text-container{
          padding: 0 25px;
        }
      }
    }
  }

  .gou-inner__main section.gou-content-block.section-video-block {
    margin: 0 -2rem;
  }

  .gou-inner__main section.section-testkit-block, .gou-inner__main section.section-text-block{
    margin: 0 -1rem;
  }
  body .gou-page-header__container .gou-page-header__inner .gou-page-header__text-left .gou-breadcrumbs .breadcrumbs, body .gou-page-header__container .gou-page-header__inner .gou-page-header__cta-right{
    display: none;
  }
  body .section-grid-slider .gou-section-grid__wrapper .section-grid-slider-item {
    width: 350px;
  }
  body #main-content > .inner-page-contents > .inner-page-contents > .grid-x > .cell > .block_area_aftercontent .gou-section-grid .gou-section-grid-feature-text-box__container .gou-section-grid__heading{
    margin-top: 32px;
  }
  body #main-content > .inner-page-contents > .inner-page-contents > .grid-x > .cell > .block_area_aftercontent .gou-section-grid .gou-section-grid-feature-text-box__container .gou-section-grid-feature-text-box__feature-container {
    margin-bottom: 50px;

    .feature-text-box-gutter{
      display: none;
    }
    .feature-text-box {
      width: 100%;
      padding: 0;
      margin-bottom: 0;
      border: none;
      position: relative;
      border-bottom: 1px solid #252525 !important;

      .feature-title{

        &:hover{
          background-color: rgba(60, 72, 83, 0.1);
        }

        &:before{
          content: '+';
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }

      }

      p{
        padding: 0 46px 0 10px;
        margin: 0;
        overflow: hidden;
        overflow-y: auto;
        height: 100%;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s ease;
      }

      &.active{

        .feature-title{
          position: relative;

          &:before{
            content: '-';
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        p{
          padding: 0 46px 30px 10px;
          max-height: 2000px;
        }
      }
    }
  }

  body .section-text-block .gou-section-grid .gou-section-grid-text-block__feature-container .section-grid-text-block {
    border-bottom: 1px solid #252525 !important;

    .section-grid-text-block-title{
      position: relative;
      font-size: 20px;
      letter-spacing: 0.5px;
      padding: 10px 10px 20px;
      margin-bottom: 0;

      &:hover{
        background-color: rgba(60, 72, 83, 0.1);
      }

      &:before{
        content: '+';
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    p{
      padding: 0 46px 0 10px;
      margin: 0;
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease;
    }
    &.active{

      .section-grid-text-block-title{

        &:before{
          content: '-';
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      p{
        padding: 0 46px 30px 10px;
        max-height: 2000px;
      }
    }
  }

  body .gou-header .gou-search__container {
    padding-right: 1rem !important;
    padding-left: 1rem!important;
  }
  body .gou-header__button > span {
    display: inline-block;
  }
}

@media print, screen and (max-width: 525px){
  body {
    background-position: 56% 0;
    background-size: 320%;
  }
  body #main-content > .gou-main-content, body #main-content > .inner-page-contents, body #main-content.gou-inner.gou-inner--video__main .video-page-template {
    padding-top: 32px;
  }
  body .gou-page-header__container .gou-page-header__inner .gou-page-header__cta-right{
    display: none;
  }
  body .section-feature-icon-block.mobile-list-layout .gou-section-grid-feature-icon__container .gou-section-grid-feature-icon__bottom .gou-section-grid-feature-icon__feature-container .feature{

   .feature-image {
    max-width: 75px;
    width: 25%;
   }

    p{
      width: 75%;
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
  body .section-testkit-block .gou-section-grid-testkit__container .gou-section-grid-testkit__inner-left h2.gou-section-grid__heading{
    font-size: 35px;
    font-weight: 500;
    line-height: 1.09;
    letter-spacing: 0.88px;
  }
  body .section-testkit-block .gou-section-grid-testkit__container .gou-section-grid-testkit__inner-left h2.gou-section-grid__heading::before {
    content: '';
    height: 0.9em;
    display: block;
    width: 38%;
  }
}

@media print, screen and (max-width: 473px){
  body .off-canvas-wrapper .off-canvas.position-right.is-transition-push {
    width: 100%;
  }
  body .home-page .gou-homeheadlines .gou-section-grid .gou-homeheadlines__wrapper .headline-chat__container{
    position: relative;

    > a.chat-button{
      position: absolute;
      bottom: -378px;
      margin-left: calc(50% - 55px);
    }
  }
  body .home-page .section-text-cta-block{
    margin-top: 64px;
  }
}

@media print, screen and (max-width: 402px){
  body .gou-header .gou-header__nav-toggle {
    right: 25px;
  }
  .gou-section-grid__bottom.swiper2.swiper-initialized.swiper-horizontal .gou-section-grid__item{
    width: 75%;
  }
  body .section-grid-slider .gou-section-grid__wrapper .section-grid-slider-item {
    width: 55%;
  }
}