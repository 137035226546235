.gou-sidebar {
    max-width: 50em;
    margin: auto;
    padding-bottom: 2em;

    // sass-lint:disable force-pseudo-nesting
    > div:not(:last-child) {
        margin-bottom: 2em;
        padding-bottom: 1em;
        border-bottom: rem-calc(1) $light-gray solid;
    }
}
