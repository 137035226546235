$gou-profile-base-size: 1rem;
$gou-profile-line-height: 1.2;

$gou-profile-heading-size: 1.2rem;
$gou-profile-heading-line-height: 1.1;
$gou-profile-heading-margin: 0.5rem;

.gou-profile {
    @include component-reset;
    background: $white;
    box-shadow: 0 3px 6px opacify($black, 0.16), 0 3px 6px opacify($black, 0.23);
    text-align: center;

    dt {
        @include element-invisible;
    }

    dd {
        display: inline-block;
    }

    &__middle {
        height: (2 * ($gou-profile-base-size * $gou-profile-line-height)) + 2rem + (2 * ($gou-profile-heading-size * $gou-profile-heading-line-height)) + $gou-profile-heading-margin;

        dl {
            font-size: $gou-profile-base-size;
            line-height: $gou-profile-line-height;
        }


        + dl {
            margin-bottom: 0;
            padding-top: 1em;
            border-top: rem-calc(1) $medium-gray solid;
        }
    }

    &__name {
        margin-bottom: $gou-profile-heading-margin;
        text-align: center;
    }

    &__location {
        &:before {
            display: inline;
            content: ' - ';
        }
    }

    &__content {
        padding: 2em 1.25em 1.25em;
    }

    &__image {
        max-width: 80%;
        margin: 0 auto 2em;
        border-radius: 50%;
        overflow: hidden;
    }

    &__email {
        @include button(false, get-color(secondary), darken(get-color(secondary), 5%));
        @include gough-button;
        margin-bottom: 0;
    }

    &__phone {
        @include button(false, transparent, darken(get-color(secondary), 5%));
        margin-bottom: 0;
    }

    &__email,
    &__phone {
        padding-top: 0.5em;
        padding-bottom: 0.425em;
    }
}
