$gou-card-base-size: 1rem;
$gou-card-line-height: 1.4;
$gou-card-base-color: $black;
$gou-card-title-size: rem-calc(22);
$gou-card-title-line-height: 1.2;
$gou-card-cta-title-size: rem-calc(18);
$gou-card-cta-title-line-height: 1.2;
$gou-card-link-bg-color: get-color(secondary);

.gou-card {
    @include component-reset($gou-card-base-color, $gou-card-base-size);

    .gou-content-block--bg-black &,
    .gou-content-block--bg-image &,
    .gou-section-grid--inverted & {
        color: $white;
    }

    &__wrapper {
        display: block;
        text-decoration: none;

        &:hover {
            .gou-card__image {
                opacity: 0.8;
            }

            .gou-card__link {
                background-color: darken($gou-card-link-bg-color, 10%);
            }

            .gou-card__heading {
                opacity: 0.8;
            }
        }
    }

    &__heading {
        @include multiline-truncate($gou-card-title-size, $gou-card-title-line-height, 1);
        margin-bottom: 0.5em;
        transition: opacity 0.25s $global-easing;
        color: currentColor;

        svg {
            display: none;
        }
    }

    &__subheading {
        margin-top: 0.5em;
        margin-bottom: 1em;
        text-transform: none;
    }

    &__image {
        display: none;
        margin-bottom: 0.5em;
        transition: opacity 0.25s $global-easing;
        @include breakpoint(medium) {
            display: block;
        }

        [href*='vimeo'] &,
        [href*='youtube'] &,
        .gou-section-grid--videos & {
            position: relative;
            display: block;

            &:after {
                @include pseudo;
                @include absolute-center;
                z-index: 10;
                width: 3em;
                height: 3em;
                background-image: url('../img/icon__play.svg');
                background-size: 100%;
            }
        }

        img {
            height: inherit;
        }

        .lazyload {
            opacity: 0.2;
        }

        .lazyloaded {
            opacity: 1;
        }
    }

    &__description {
        @include multiline-truncate($gou-card-base-size, $gou-card-line-height, 3);
        margin-bottom: 1em;
        font-family: $gough-heading-font;
    }

    &__link {
        @include button($button-padding, $gou-card-link-bg-color, false);
        @include gough-button();
        margin-bottom: 0;
        text-align: left;

        @include breakpoint(medium) {
            display: inline-block;
            width: auto;
        }
    }

    &--document {
        .gou-card__heading {
            margin-bottom: 0;
            text-transform: none;
        }
    }

    &--cta {
        .gou-section-grid--inverted & {
            color: darken($gough-yellow, 5%);
        }

        .gou-card__heading {
            @include multiline-truncate(rem-calc(14), $gou-card-cta-title-line-height, 1);
            @include breakpoint(large) {
                @include multiline-truncate($gou-card-cta-title-size, $gou-card-cta-title-line-height, 1);
            }

            svg {
                display: inline-block;
            }
        }

        .gou-card__image {
            display: block;
        }
    }
}
