@mixin gough-button() {
    vertical-align: middle;
    font-family: $gough-heading-font;
    font-size: inherit;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;

    svg {
        float: right;
        height: 1em;
        margin-top: rem-calc(-1);
    }
}

.button {
    @include gough-button();

    &.small {
        padding-top: 0.5em;
        padding-bottom: 0.425em;
    }

    &.expanded {
        svg {
            float: none;
        }
    }
}
