.gou-footer {
    @include component-reset($white);
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: rem-calc(1) $gough-yellow solid;

    text-align: center;
    @include breakpoint(large) {
        text-align: inherit;
    }

    &__container {
        @include xy-grid-container;

    }

    &__wrapper {

    }

    &__top {
        @include xy-grid();
        margin-bottom: 64px;
    }

    &__bottom {
        @include xy-grid();
    }

    &__nav {
        @include breakpoint(large) {
            margin-left: -1em;
        }
    }

    &__group {
        @include breakpoint(large) {
            margin: 1.25em 0;
        }

        svg {
            width: rem-calc(171);
            height: rem-calc(44);
        }
    }

    &__group-tag {
        display: inline-block;
        vertical-align: top;
        margin: 0.75em;
    }
}
