$gou-caption-base-size: 1rem;
$gou-caption-line-height: 1.4;
$gou-caption-link-bg-color: get-color(secondary);

.gou-caption {
    &__heading-number {
        display: block;
        font-size: rem-calc(40);
        font-weight: 700;
        line-height: 1;
    }

    &__heading-text {
        display: block;
        padding-top: 0.875em;
        border-top: rem-calc(1) currentColor solid;
    }

    &__content {
        @include multiline-truncate($gou-caption-base-size, $gou-caption-line-height, 3);
        margin-bottom: 1em;
        font-family: $gough-heading-font;

        .gou-figure & {
            padding-right: 2em;
        }
    }

    &__link {
        @include button($button-padding, $gou-caption-link-bg-color, false);
        @include gough-button();
        text-align: left;

        @include breakpoint(medium) {
            display: inline-block;
            width: auto;
        }
    }


    &--hide-number-on-large {
        .gou-caption__heading-number {
            @include breakpoint(large) {
                display: none;
            }
        }

        .gou-caption__heading-text {
            @include breakpoint(large) {
              border-top: 0;
            }
        }
    }
}
