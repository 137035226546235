.gou-main-nav {
    &__child-link {
        &#{&} {
            font-size: 0.825em;
        }
    }
    .menu > li.gou-main-nav__item{
        font-size: 0.966em;
    }
    &__link,
    &__child-link {
        .current > &,
        .active > & {
            background-color: $gough-yellow !important;
            color: $gough-black !important;

            &:hover {
                color: $gough-black !important;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 0.5em;
        right: 0.5em;

        .is-drilldown-submenu & {
            display: none;
        }
    }

    &__close-button {
        width: 2em;
        padding: 1rem;
        font-size: 1.875em;
        color: $gough-yellow;

        svg {
            width: 100%;
        }
    }

    &--horizontal {
        display: none;

        @include breakpoint(large) {
            @include xy-cell(auto, true);
            display: block;
        }

        .gou-main-nav__link {
            padding: 0.875rem 0.8em 0.750rem !important;

            @include breakpoint(large only) {
                padding: 0.875rem 0.8em 0.675rem  !important;
            }
        }

        .submenu {
            &.js-dropdown-active {
                animation-duration: 0.25s;
                animation-name: custom-1;
                animation-timing-function: $global-easing;
            }
        }

        svg {
            display: none;
        }
    }

    &--vertical {
        @include component-reset($white, 1rem);

        .gou-main-nav__list {
            display: block;
        }

        .gou-main-nav__link {
            display: block;
            position: relative;
        }

        .is-drilldown {
            position: static;
            margin-bottom: 1em;
        }

        .is-drilldown-submenu {
            background-color: $dropdown-background;
        }
    }
}
