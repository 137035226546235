$gou-header-gutter: $grid-column-gutter;
$gou-extra-breakpoint: 690;

.gou-header {
    @include component-reset($white, 0.875rem);
    position: relative;
    z-index: 1000;
    background-color: unset;
    padding-top: 22px;

    @include breakpoint($gou-extra-breakpoint) {
        font-size: 1rem;
    }

    &__container {
        @include xy-grid-container;
        padding: 0;
        margin: 0 auto;
    }

    &__wrapper {
        @include xy-grid(horizontal, true);
        @include flex-align(justify, top);
    }

    &__logo {
        @include xy-cell(shrink, true, map-get($gou-header-gutter, small), margin, top left right bottom, null, false);
        font-size: 2.75em;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        margin-top: unset;

        @include breakpoint($gou-extra-breakpoint) {
            @include xy-cell(auto, true, $gou-header-gutter, margin, top  left bottom, null, false);
            margin-right: 0;
        }

        @include breakpoint(large) {
            @include xy-cell(shrink, true, $gou-header-gutter, margin, top right left, null, false);
        }
        img {
             height: 97px;
             margin-top: 0;
        }
    }

    &__logo-link {
        display: block;
        width: 5.4em;

        svg {
            display: block;
            width: 100%;
            height: 1em;
            margin: 0 auto 0 0;
        }
    }

    &__nav-toggle {
        @include xy-cell(shrink, true, map-get($gou-header-gutter, small), margin, top left bottom, null, false);


        @include breakpoint(large) {
            display: none;
        }

        button {
            @include hamburger($gough-yellow, darken($gough-yellow, 0.5), 2.5em, 1.5em, 0.125em, 3);
            margin-top: 0.5em;
        }
    }

    &__search-toggle {
        @include xy-cell(shrink,  true, map-get($gou-header-gutter, small), margin, top right bottom, null, false);
        text-align: right;
        @include breakpoint($gou-extra-breakpoint) {
            display: none;
        }

        button {
            @include button(false, $gough-yellow, $light-gray, $black, solid);
            @include gough-button();
            max-width: 3em;
            margin-bottom: 0;
        }
    }

    &__top-nav {
        display: none;
        @include breakpoint(large) {
            @include flex;
            @include xy-cell(auto, true, $gou-header-gutter, margin, top left bottom, null, false);
            @include flex-align(right, middle);
        }
    }

    &__bottom {
        @include component-reset($white, 0.875rem);
        background-color: unset;
        @include breakpoint($gou-extra-breakpoint down) {
            position: absolute;
            top: 5.625em;
            right: map-get($grid-column-gutter, 'small') / 2;
            left: map-get($grid-column-gutter, 'small') / 2;
            z-index: -1;
            opacity: 0;
            transform: scale(0.8);
            transition: all 0.2s $global-easing;
            pointer-events: none;
        }
        @include breakpoint($gou-extra-breakpoint) {
            position: relative;
        }
        @include breakpoint(xlarge) {
            font-size: 1rem;
        }
    }

    &__bottom-container {
        @include xy-grid-container;
    }

    &__bottom-wrapper {
        @include xy-grid();
    }

    &__search {
        @include xy-cell(100%, false, 0);
        @include breakpoint($gou-extra-breakpoint) {
            @include xy-cell(100%);
            padding-top: map-get($grid-column-gutter, 'medium') / 2;
            padding-bottom: map-get($grid-column-gutter, 'medium') /2;
        }
        @include breakpoint(large) {
            @include xy-cell(shrink, true, 0, margin, top left bottom, null, false);
            margin-right: 1rem;
            padding: 0;
        }
    }

    &__close-search {
        top: 0;
        right: 0.75rem;
        bottom: 0;
        display: inline-block;
        width: 3em;
        margin-bottom: 0;
        font-size: inherit;

        svg {
            width: 100%;
        }
        @include breakpoint($gou-extra-breakpoint) {
            display: none;
        }
    }

    &__buttons {
        @include xy-cell(shrink,  true, map-get($gou-header-gutter, small), margin, top left right bottom, null, false);
        min-width: 70%;
        margin-bottom: 0;
        white-space: nowrap;

        @include breakpoint($gou-extra-breakpoint) {
            @include xy-cell(shrink, true, $gou-header-gutter, margin, top right bottom, null, false);
            min-width: auto;
            margin-right: map-get($gou-header-gutter, medium)/ 2;
            text-align: right;
        }
        @include breakpoint(large) {
            @include xy-cell(shrink, true, $gou-header-gutter, margin, top right bottom, null, false);
            @include flex-align(right, middle);
        }
    }

    &__button {
        @include button(false, $medium-gray, $medium-gray, $black, hollow);
        @include gough-button();
        margin-bottom: 0;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.4px;
        text-align: center;
        text-transform: none;
        margin-left: 20px;
        min-width: 240px;
        padding: 1em;

        &.borderless-button{
            border: none;
            background: unset;
            color: white
        }

        &.rounded-button{
            border-radius: 25px ;
        }
        &.orange-button{
            border-color: #f5bf0b;
            color: #f5bf0b;
            min-width: 240px;
        }
        &.search-button{
            min-width: 0;
        }


        > span {
            display: none;

            @include breakpoint(medium) {
                display: inline-block;
            }
        }

        &[data-login-active='true'] {
            opacity: 0.65;
        }
    }

    &--search-expanded {
        .gou-header__bottom {
            z-index: 100;
            opacity: 1;
            transform: scale(1);
            pointer-events: auto;
        }
    }
}
