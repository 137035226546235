.gou-breadcrumbs {
    @include component-reset($gough-black, 0.875rem);
    background-color: $light-gray;
    font-family: $gough-heading-font;
    text-transform: uppercase;

    ul {
        max-width: map-get($breakpoints, xlarge);
        margin: 0 auto;
        padding: 1rem 1.25rem;

        @include breakpoint(medium) {
            padding: 1rem 2rem;
        }
    }

    li {
        font-size: 1em;
    }

    a {
        text-decoration: none;
    }
}
