$gou-figure-dot-radius: 0.9em;
$gou-figure-number-offset: 2.5em;

.gou-figure {
    @include xy-grid();

    &__image,
    &__caption {
        @include xy-cell(100%, true, 0, margin, top right left, null, false);

        @include breakpoint(medium) {
            @include xy-cell(1 of 2, true, 0, margin, top right left, null, false);
        }
    }

    &__image {
        position: relative;
        margin-bottom: 0.5em;

        @include breakpoint(medium) {
            @include flex-order(2)
            margin-top: $gou-figure-number-offset;
            margin-bottom: 0;
        }

        &:after {
            top:  -$gou-figure-dot-radius;
            left: -$gou-figure-dot-radius;
            width: $gou-figure-dot-radius * 2;
            height: $gou-figure-dot-radius * 2;
            border: rem-calc(5) $light-gray solid;
            border-radius: 50%;
            background-color: $gough-yellow;

            @include breakpoint(medium) {
                @include pseudo;
            }
        }
    }
}
