@charset 'utf-8';
/* Our Mixins, no Dependancies */
@import 'helpers/utility-mixins';
/* Vendor */
//@import 'elements/slick';
//@import 'elements/slick-theme';
/* Brand Colours as Vars */
@import 'colors';
/**
 *  Core foundation
 */
@import '../../../node_modules/foundation-sites/scss/foundation';
@import 'settings';
@import 'motion-ui';
@import "../../../node_modules/swiper/swiper";
@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
// @include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes; // @include foundation-float-classes;
@include foundation-flex-classes; // @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;
/**
 * Helpers and Additional Typography
 */
@import 'helpers/utility-classes';
@import 'typography';
/**
 * Elements
 */
@import 'elements/button';
@import 'elements/input';
@import 'elements/logo';
/**
 *  Components
 */
@import 'components/login';
@import 'components/header';
@import 'components/menu';
@import 'components/search';
@import 'components/search-results';
@import 'components/main-nav';
@import 'components/content-block';
@import 'components/main-content';
@import 'components/sidebar';
@import 'components/diagram';
@import 'components/caption';
@import 'components/gallery';
@import 'components/banner';
@import 'components/breadcrumbs';
@import 'components/section-grid';
@import 'components/pagination';
@import 'components/figure-list';
@import 'components/figure';
@import 'components/card';
@import 'components/profile';
@import 'components/form';
@import 'components/finder';
@import 'components/map';
@import 'components/footer';
@import 'components/credit';
@import 'components/sitemap';
@import 'components/testkit';
/**
 *  Components
 */
@import 'components/custom';