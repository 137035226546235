.gou-search-results {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        margin-bottom: 2em;
    }

    &__meta {
        margin-bottom: 0;
    }

    &--grid {
        @include xy-grid();

        .gou-search-results__item {
            @include xy-cell(100%, true, map-get($grid-column-gutter, 'small'), margin, top right left bottom);

            @include breakpoint(medium) {
                @include xy-cell(1 of 2, true, map-get($grid-column-gutter, 'medium'), margin, top right left bottom);
            }

            @include breakpoint(large) {
                @include xy-cell(1 of 3, true, map-get($grid-column-gutter, 'medium'), margin, top right left bottom);
            }

            @include breakpoint(xlarge) {
                @include xy-cell(1 of 4, true, map-get($grid-column-gutter, 'medium'), margin, top right left bottom);
            }
        }

        .gou-search-results__meta {
            dt {
                @include element-invisible;
            }
        }
    }
}
