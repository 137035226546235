.menu {
    font-family: $gough-heading-font;
    font-weight: 700;
    text-transform: uppercase;

    a {
        transition: all 0.25s ease-out;

        &:hover {
            color: $gough-yellow;
        }

        &:active {
            color: darken($gough-yellow, 0.5%);
        }
    }
}

.gou-menu {
    .gou-footer__nav & {
        @include flex-align(center, null);
        @include breakpoint(large) {
            @include flex-align(left, null);
        }
    }

    &__link {
        position: relative;
        transition: all 0.25s ease-out;

        &#{&} {
            &:hover,
            .current > &,
            .active > & {
                background-color: transparent;
                color: $gough-yellow;
            }
        }

        .gou-footer__nav & {
            @include breakpoint(small only) {
                padding: 0.75em 0.25em 0.6em;
            }
        }

        &:after {
            .gou-footer__nav & {
                display: none;
            }

            // sass-lint:disable force-pseudo-nesting
            li:not(:last-child) & {
                position: absolute;
                top: 0.625em;
                right: -0.125em;
                content: '|';
                color: currentColor;
            }
        }
    }
}
