.gou-sitemap {
    &__menu {
        #{& &} {
            margin-left: 1em;
        }
    }

    &__link {
        padding-left: 0 !important;
    }

    &__grandchild-link,
    &__child-link {
        font-weight: 400;
        text-transform: none;
    }
}
