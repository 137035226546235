.gou-login {
    @include component-reset($white, 0.875rem);
    position: relative;
    display: none;
    border-bottom: rem-calc(1) $gough-yellow solid;
    background-color: $gough-black;

    &__container {
        @include xy-grid-container;
    }

    &__wrapper {
        @include xy-grid(horizontal, true);
        @include flex-align(right, top);
    }

    &__form {
        @include breakpoint(small) {
            @include xy-cell(auto, true, map-get($grid-column-gutter, 'small'), margin, top left right bottom, null, false);
        }
    }

    &--active {
        display: block;
    }
}
