.gou-gallery {
    @include component-reset($white);

    &__item {
        position: relative;
        display: block;

        & + & {
            margin-top: 1em;
            @include breakpoint(large) {
                float: left;
                width: calc(50% - 1em);
                margin-top: 2em;
            }

            &:nth-child(2n + 1) {
                @include breakpoint(large) {
                    margin-left: 2em;
                }
            }
        }

        &[href*='youtube'] {
            &:after {
                @include pseudo;
                @include absolute-center;
                z-index: 10;
                width: 3em;
                height: 3em;
                background-image: url('../img/icon__play.svg');
                background-size: 100%;
            }
        }
    }

    &__image {
        @include responsive-embed(3 by 2);
        margin-bottom: 0;
        background-color: $light-gray;
    }

    &__heading {
        margin-top: 0;
    }

    &__content {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 1em;
        background-color: rgba($black, 0.4);
    }

    &__caption {
        @include multiline-truncate;
        margin-bottom: 0;

        + div {
            margin-top: 0.6em;
        }
    }

    &--carousel {
        @include slick-controls;

        .rm-gallery__item {
            width: 100%;
            margin: 0;
        }

        .slick-dots {
            bottom: 1em;
        }

        .rm-gallery__content {
            padding-bottom: 2.5em;
        }
    }
}
