/* adjacent Headings*/
main {
    background-color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: none;
}

h1 + h2,
h1 + h3,
h2 + h3 {
    margin-top: 1rem;
}

h2 + h4,
h3 + h4,
h3 + h5,
h3 + h5 {
    margin-top: 0;
}

h6 {
    font-weight: 400;
}

dd + dt {
    margin-top: 1em;
}

legend {
    font-family: $header-font-family;
    font-size: 1.5em;
    font-style: $header-font-style;
    font-weight: $header-font-weight;
}

blockquote {
    font-size: 1.125em;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

cite {
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

.svg-icon {
    vertical-align: middle;
    max-width: 1em;
    max-height: 1em;
}

.callout {
    &.validation {
        @include callout-style(get-color(alert));
    }
}
