.gou-banner {
    position: relative;
    background-color: $light-gray;
    overflow: hidden;
    display: none;

    @include breakpoint(medium) {
        display: block;
        height: between(350px, 400px, map-get($breakpoints, medium), map-get($breakpoints, large));
        max-height: rem-calc(400);
        margin-top: -2em;
        border-bottom: rem-calc(5) $gough-yellow solid;
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        transition: opacity 1s $global-easing;
    }
}
