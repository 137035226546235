.gou-content-block {
    background-color: $white;

    &--bg-image {
        background-color: $light-gray;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;
    }

    &--bg-black {
        background-color: $dark-gray;
        color: $white;
    }
}
