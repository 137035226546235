$gou-section-grid-rows: 1;
$gou-section-grid-max: 120rem;
$gou-section-grid-selector: '.gou-section-grid__item';
$gou-section-grid-breakpoint-for-2: 35rem;
$gou-section-grid-breakpoint-for-3: 50rem;
$gou-section-grid-breakpoint-for-4: 65rem;

.gou-section-grid {
    @include xy-grid-container;
    padding-top: 1em;
    padding-bottom: 1em;

    &__wrapper {
        @include xy-grid();

    }

    &__content,
    &__heading {
        @include xy-cell(100%, true, $grid-column-gutter, margin, top right left, null, false);
        @include breakpoint($gou-section-grid-breakpoint-for-4) {
            @include xy-cell(100%, true, map-get($grid-column-gutter, 'medium'), margin, top right left, null, false);
        }
    }

    &__header {
        margin-bottom: 0;
    }

    &__content {
        // sass-lint:disable force-pseudo-nesting
        p:not(:first-child) {
            @include breakpoint(small only) {
                display: none;
            }
        }
    }

    & &__item {
        @include xy-cell(100%, true, $grid-column-gutter, margin, top right left bottom, null, false);
        @include breakpoint($gou-section-grid-breakpoint-for-2) {
            @include xy-cell(1 of 2, true, $grid-column-gutter, margin, top right left bottom, null, false);
        }
        @include breakpoint($gou-section-grid-breakpoint-for-3) {
            @include xy-cell(1 of 3, true, map-get($grid-column-gutter, 'medium'), margin, top right left bottom, null, false);
        }
        @include breakpoint($gou-section-grid-breakpoint-for-4) {
            @include xy-cell(1 of 4, true, map-get($grid-column-gutter, 'medium'), margin, top right left bottom, null, false);
        }

        &:not(:last-child) {
            margin-bottom:  map-get($grid-column-gutter, 'medium');
        }
    }

    &--with-content-left {
        .gou-section-grid__container {
            @include xy-grid();
            @include breakpoint(large) {
                margin-right: -0.5em;
            }
        }

        .gou-section-grid__heading {
            @include breakpoint(large) {
                @include xy-cell(100%, true, map-get($grid-column-gutter, 'medium'), margin, top right left, null, false);
            }
        }

        .gou-section-grid__top {
            @include xy-cell(100%, true, $grid-column-gutter , margin, top right left bottom, null, false);
            @include breakpoint(large) {
                @include xy-cell(1 of 3, true, map-get($grid-column-gutter, 'medium'), margin, top right left, null, false);
            }
        }

        .gou-section-grid__bottom {
            display: block;
            width: 100%;

            @include breakpoint(large) {
                @include xy-cell(2 of 3, true, map-get($grid-column-gutter, 'medium'), margin, top right left, null, false);
            }
        }

        .gou-section-grid__content {
            margin: 0;
        }

        .gou-section-grid__item {
            @include xy-cell(1 of 2, true, $grid-column-gutter, margin, right left bottom, null, false);
            margin-top: 0;
            @include breakpoint($gou-section-grid-breakpoint-for-2) {
                @include xy-cell(1 of 3, true, $grid-column-gutter, margin, right left bottom, null, false);
            }
            @include breakpoint($gou-section-grid-breakpoint-for-3) {
                @include xy-cell(1 of 4, true, $grid-column-gutter, margin, right left bottom, null, false);
            }
            @include breakpoint($gou-section-grid-breakpoint-for-4) {
                @include xy-cell(1 of 4, true, $grid-column-gutter, margin, right left bottom, null, false);
            }
        }
    }

    &--inverted,
    .gou-content-block--bg-image &,
    .gou-content-block--bg-black & {
        @include component-reset($white);
    }
}
