
.testkit-container {
    &.gou-content-block--bg-black {
        background-color: #333;
        color: #fff;
        background-color: #404851;
        //background-image: url(../img/bg-oilswirl.jpg);
        //background-position: top;
        //background-size: cover;
        //background-repeat: no-repeat;
        //background-size: 180%;
        //background-position: 90% 50%;
        padding: 64px 0;
    }
    .gou-profile__content {
        padding: 0.75em;
    }

    .gou-section-grid__item {
        @include breakpoint($gou-section-grid-breakpoint-for-4) {
            @include xy-cell(1 of 3, true, map-get($grid-column-gutter, 'medium'), margin, top right left bottom, null, false);
        }
    }


    .gou-profile__image {
        border-radius: 0;
        height: 200px;
        overflow: hidden;
        max-width: inherit;

        img {
            object-fit: cover;
        }
    }

    .gou-profile__middle {
        height: 100px;
        overflow: hidden;
    }

    .gou-profile__details {
        margin-bottom: 0.2em;
        padding-top: 1em;
        border-top: 0.0625rem #ccc solid;
        width: 100%;
        position: relative;
        display: block;
    }
    .gou-profile_quantity_container{
        padding: 2em 0;
        border-top: 0.0625rem #ccc solid;
        width: 100%;
        position: relative;
        display: block;

        h5{ 
            font-size: 0.8rem;
            opacity: 0.6;
            letter-spacing: 0.1rem;
            margin-bottom: 1rem;
        }

        .gou-profile__quantity {  
            display: flex;
            width: 100%;
            position: relative;
            justify-content: center;
            justify-self: center;
          

            input {
                height: 32.8px;
                margin: 0;
                text-align: center;
                max-width: 120px;
                -webkit-appearance: none; 
                -moz-appearance: textfield;
                background-color: #e5e5e5;
                cursor: auto;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type=number] {
                -moz-appearance: textfield;
            }
            input:disabled {
                background-color: #e5e5e5;
                cursor: auto;
            }
            button{
                width: 100%;
                max-width: 60px;
                background: rgba(0, 0, 0, 0.85);
                color: #fff;
                border-radius: 15% 0 0 15%;
                font-size: 1rem;
                font-weight: 500;
                span{
                    font-size: 1.6rem;
                    position: relative;
                    display: block;
                }
                &.increase{
                    border-radius: 0 15% 15% 0;
                    color: #fff;
                }
                &:hover{
                    background: rgba(0, 0, 0, 1);   
                    font-weight: 600;
                    transition: 0.1s;
                    transition-timing-function: ease-in-out;   
                }
            }
        }

        .gou-profile__addbtn {
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 1rem 0;
            font-family: inherit;
            padding: 0.5em 1em 0.425em 0.6em;
            -webkit-appearance: none;
            border: 1px solid transparent;
            border-radius: 0;
            transition: background-color 0.25s ease-out, color 0.25s ease-out;
            font-size: 1rem;
            line-height: 1;
            text-align: center;
            cursor: pointer;
            background-color: #000;
            color: #fff;
            vertical-align: middle;
            font-family: "Univers Condensed", sans-serif;
            font-size: inherit;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
            width: 49%;
            margin: -5px 0 0;

            &:hover {

                color: #ffe500;
            }

            svg {
                float: right;
                height: 1em;
                margin-top: -0.0625rem;
            }
        }
    }

    .gou-section-grid__pagination {
        button#submit {
            display: block;
            margin: 1rem auto;
            //font-family: inherit;
            //padding: 0.5em 1em 0.425em 0.6em;
            //-webkit-appearance: none;
            //border: 1px solid transparent;
            //border-radius: 0;
            //transition: background-color 0.25s ease-out, color 0.25s ease-out;
            //font-size: 1rem;
            //line-height: 1;
            //text-align: center;
            //cursor: pointer;
            //background-color: #ffe500;
            //color: #000;
            //vertical-align: middle;
            //font-family: "Univers Condensed", sans-serif;
            //font-size: inherit;
            //font-weight: 700;
            //text-decoration: none;
            //text-transform: uppercase;
            //width: 100%;
            //max-width: 700px;

            &:disabled{                
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }
}