.gou-form {

    label {
        margin-top: 0.5625rem;
        margin-bottom: 0;
        text-transform: uppercase;

        .gou-login & {
            @include element-invisible;
        }
    }

    input, select, textarea {
        margin-bottom: 0;
        border-radius: 0;
        border: 1px solid #000;
        padding-left: 1rem;
    }
    textarea {
        padding: 1rem;
    }
    [type='checkbox'] {
        // sass-lint:disable force-pseudo-nesting
        &:first-child:last-of-type {
            display: inline;

            + label {
                display: inline;
            }
        }
    }

    [type='reset'],
    [type='submit'] {
        // @include button-expand(true);

        @include breakpoint(medium) {
            @include button-expand(false);
            min-width: 10rem;
            max-width: 10rem;
        }

        .gou-login & {
            min-width: 6.5em;
        }
    }

    &__fieldset {
        @include xy-grid;
        @include flex-align(left, stretch);

        @include breakpoint(medium) {
            @include flex-align(right, stretch);
        }
    }

    &__legend,
    &__username,
    &__password,
    &__submit,
    &__menu {
        @include xy-cell(100%, true, map-get($grid-column-gutter, 'medium'), margin, bottom, null, false);

        .gou-login & {
            @include breakpoint(medium) {
                @include xy-cell(shrink, true, map-get($grid-column-gutter, 'small') / 2, margin, left, null, false);
                margin-bottom: 0;
            }
        }

        .gou-inner--login & {
            @include xy-cell(100%, true, 0);
        }
    }

    &__legend {
        legend {
            margin-bottom: 0;

            .gou-login & {
                margin-right: 1em;
                font-size: 1em;
                line-height: 2.325;
                text-transform: uppercase;
            }
        }

        .gou-inner--login & {
            display: none;
        }
    }

    &__username,
    &__password {
        input {
            .gou-login & {
                border-color: transparent;
            }
        }

        .gou-login & {
            @include breakpoint(medium) {
                max-width: 15em;
            }
        }
    }

    &__submit {
        .gou-inner--login & {
            margin-top: map-get($grid-column-gutter, 'small');
            margin-bottom: map-get($grid-column-gutter, 'small') / 2;
        }
    }

    &__menu {
        margin-bottom: 0;
        margin-left: -1em;
        margin-right: -1em;

        .gou-inner--login & {
            .gou-menu {
                margin-left: -1em;
            }
        }
    }

    .form-error {
        margin-top: 0;
    }

    &--contact {
        padding-top: 3rem;
        //border-top: $hr-border;

        hr {
            margin: 4rem auto 3.5rem;
            border-bottom: 1px solid #000;
        }
    }

    &--login {
        margin-bottom: 0;

        .gou-inner--login & {
            padding-top: 2em;
            border-top: 1px solid $medium-gray;
            margin-bottom: 4rem;
        }

        [type='submit'] {
            width: auto;
            margin-bottom: 0;
        }

        [type='text'],
        [type='password'] {
            height: 2.325em;
            border-radius: 0;
            font-size: 1em;
        }
    }

    .medium-3.large-3.cell {
        display: none;
    }
    .grid-x.light-gray--40.small-p-1rem.small-pt-0 {
        padding: 0;
        background-color: #fff;

        label {
            display: none;
        }
        textarea{
            min-height: 300px;
        }
    }
    label{
        display: none;
    }
    [type='checkbox']:first-child:last-of-type {
        display: inline;
        width: 25px;
        height: 25px;

        &+ label {
            position: absolute;
            font-size: 18px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: 0.45px;
            text-transform: none;
            margin-top: -3px;
        }
    }
    .g-recaptcha{
        padding: 10px 0 50px;
    }
    [type='submit']{
        background-color: #fff;
        padding-left: 0;
        min-width: 0;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.55px;
        margin-bottom: 0;

        .submit__icon{
            width: 30px;
            height: 30px;
            margin-left: 9px;
            position: relative;
            transition: all 0.3s ease;

            svg{
                position: absolute;
                margin-top: 3px;
                transition: all 0.3s ease;
            }
        }

        &:hover{
            background-color: #fff;

            .submit__icon{

                svg {
                    margin-left: 5px;
                }
            }
        }
        &#Form_ContactForm_action_doContactForm{
            background-color: unset !important;
            min-width: 120px;
        }

    }
    [type='reset']{
        background-color: #fff;
        min-width: 0;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.38px;
        text-transform: uppercase;
        text-decoration: underline;
        margin-left: 10px;
        margin-bottom: 0;


        &:hover{
            background-color: #fff;
            text-decoration: none;
        }
    }
}


hr {
    margin: 3rem auto;
    border-bottom: 1px solid #000;
}